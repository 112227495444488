/* eslint-disable */

import { EventEmitter } from "events";
import protooClient from "protoo-client";
import bowser, { parse } from "bowser";
import { v4 as uuidv4 } from "uuid";
import * as mediasoupClient from "mediasoup-client";
import { GoigiStorageHelper } from "./goigi.store";

//  Global Variables
let mediaStreamTracks = [];

const broadcastConfiguration = {
  video: true,
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: false,
    googEchoCancellation: true,
    googAutoGainControl: false,
    googExperimentalAutoGainControl: false,
    googNoiseSuppression: true,
    googExperimentalNoiseSuppression: true,
    googHighpassFilter: true,
    googTypingNoiseDetection: true,
    googBeamforming: false,
    googArrayGeometry: false,
    googAudioMirroring: false,
    googNoiseReduction: true,
    mozNoiseSuppression: true,
    mozAutoGainControl: false,
  },
  videoSimulcastEncodings: [
    {
      scaleResolutionDownBy: 4,
      maxBitrate: 128000,
      maxFramerate: 20,
    },
    {
      scaleResolutionDownBy: 2,
      maxBitrate: 500000,
      maxFramerate: 20,
    },
    {
      scaleResolutionDownBy: 1,
      maxBitrate: 700000,
      maxFramerate: 20,
    },
  ],
  screenSimulcastEncodings: [
    {
      scaleResolutionDownBy: 1,
      dtx: true,
      maxBitrate: 756000,
    },
    {
      scaleResolutionDownBy: 1,
      dtx: true,
      maxBitrate: 1500000,
    },
  ],
};

class GoigiConferenceService {
  constructor({ licenseKey, serverUrl, proxy }) {
    // Create event emitters
    this.eventEmitter = new EventEmitter();

    // Preview Object
    this.previewObject = {
      camera: {
        videoStream: null,
        videoTagId: null,
      },
      microphone: {
        audioStream: null,
      },
    };

    // Storage Object
    this.Storage = new GoigiStorageHelper();
    this.MemStore = this.Storage.MEM_STORE;

    // License key
    this.licenseKey = licenseKey;

    // Check browser compatibility
    this.isBrowserCompatible = false;
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome && navigator && navigator.permissions) {
      this.isBrowserCompatible = true;
    }

    // Host URL
    GoigiConferenceService.prototype.SERVER_BASE_URL = serverUrl + proxy;
    GoigiConferenceService.prototype.API_KEY = licenseKey;

    // Server Routes
    this.CREATE_MEETING_API_ROUTE = "/v1/calls/create";
    this.MEETING_INFO_API_ROUTE = "/v1/calls/get";
    this.MEETING_VALIDATE_API_ROUTE = "/v1/calls/validate";
    this.JOIN_MEETING_API_ROUTE = "/v1/calls/join";
    this.PING_API_ROUTE = "/v1/calls/ping";
    this.MEETING_REOPEN_API_ROUTE = "/v1/calls/reopen";
    this.CURRENT_SERVER_URL = null;

    // Temp
    this.lazyChatTypingNotifier = null;
  }

  CheckCameraPermissions() {
    return new Promise((resolve, reject) => {
      if (!this.isBrowserCompatible) {
        return reject(
          new Error("Goigi video service does not support your browser")
        );
      }

      // Check for camera access
      navigator.permissions
        .query({ name: "camera" })
        .then((permissionObj) => {
          /**
           * 1. granted
           * 2. prompt
           * 3. denied
           */
          return resolve(permissionObj.state);
        })
        .catch((error) => {
          console.error(error);
          return reject(error);
        });
    });
  }

  CheckMicrophonePermissions() {
    return new Promise((resolve, reject) => {
      if (!this.isBrowserCompatible) {
        return reject(
          new Error("Goigi video service does not support your browser")
        );
      }

      // Check for microphone access
      navigator.permissions
        .query({ name: "microphone" })
        .then((permissionObj) => {
          /**
           * 1. granted
           * 2. prompt
           * 3. denied
           */
          return resolve(permissionObj.state);
        })
        .catch((error) => {
          console.error(error);
          return reject(error);
        });
    });
  }

  /**
   * The method allows you to check preview of broadcast before you start the live session
   * @param {*} videoTagId video tag element ID in which preview will be displayed
   */
  async StartCameraPreview({ videoTagId }) {
    try {
      // Start the video stream as per configuration
      this.previewObject.camera.videoStream = await StartNavigatorMediaSource({
        video: true,
        audio: false,
      });

      this.previewObject.camera.videoTagId = videoTagId;

      // Attach stream to DOM
      document.getElementById(this.previewObject.camera.videoTagId).srcObject =
        this.previewObject.camera.videoStream;

      // Apply configuration
      this.Storage.SAVE_SETTINGS({ camera: true, isCameraAvailable: true });

      // Emit video event
      this.eventEmitter.emit("preview-camera-state", true);

      // Store stream in global memory
      mediaStreamTracks.push(this.previewObject.camera.videoStream);
      return true;
    } catch (e) {
      console.error("StartCameraPreview: ", e);
      this.Storage.SAVE_SETTINGS({ camera: false, isCameraAvailable: false });
      return false;
    }
  }

  /**
   * Stop video preview
   */
  async StopCameraPreview() {
    try {
      // Stop all streams
      if (this.previewObject.camera.videoStream) {
        this.previewObject.camera.videoStream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      // remove srcobject
      if (document.getElementById(this.previewObject.camera.videoTagId))
        document.getElementById(
          this.previewObject.camera.videoTagId
        ).srcObject = null;

      // Save settings
      this.Storage.SAVE_SETTINGS({ camera: false, isCameraAvailable: true });

      // Emit video event
      this.eventEmitter.emit("preview-camera-state", false);
    } catch (e) {
      console.error(e);
      this.Storage.SAVE_SETTINGS({ camera: false, isCameraAvailable: false });
    }
  }

  /**
   * Start microphone preview
   */
  async StartMicrophonePreview() {
    try {
      // Start the video stream as per configuration
      this.previewObject.microphone.audioStream =
        await StartNavigatorMediaSource({
          video: false,
          audio: true,
        });

      // Apply configuration
      this.Storage.SAVE_SETTINGS({
        microphone: true,
        isMicrophoneAvailable: true,
      });

      // Emit video event
      this.eventEmitter.emit("preview-microphone-state", true);

      // Store stream globally
      mediaStreamTracks.push(this.previewObject.microphone.audioStream);

      return true;
    } catch (e) {
      console.error(e);
      this.Storage.SAVE_SETTINGS({
        microphone: true,
        isMicrophoneAvailable: false,
      });
      return false;
    }
  }

  /**
   * Stop microphone preview
   */
  async StopMicrophonePreview() {
    try {
      // Stop all streams
      if (this.previewObject.microphone.audioStream) {
        this.previewObject.microphone.audioStream
          .getTracks()
          .forEach((track) => {
            track.stop();
          });
      }

      // Save settings
      this.Storage.SAVE_SETTINGS({
        microphone: false,
        isMicrophoneAvailable: true,
      });

      // Emit video event
      this.eventEmitter.emit("preview-microphone-state", false);
    } catch (e) {
      console.error(e);
      this.Storage.SAVE_SETTINGS({
        microphone: false,
        isMicrophoneAvailable: false,
      });
    }
  }

  /**
   * Create new meeting
   */
  async CreateNewMeetingOld({
    licenseKey,
    meetingName,
    meetingPassword,
    isPasswordEnabled,
    hostName,
    hostEmail,
    hostId,
    meetingType,
  }) {
    if (!licenseKey || !hostId || !hostEmail || !hostName) {
      throw new Error("Host details missing - licenseKey, HostID, HostEmail, HostName");
    }

    // Reset Store
    this.Storage.RESET_STORE({ user: true, meeting: true });

    const requestBody = {
      apiKey: licenseKey,
      hostId: hostId,
      hostName: hostName,
      hostEmail: hostEmail,
      meetingName: meetingName ? meetingName : "Meeting 1",
      meetingPassword: meetingPassword ? meetingPassword : "",
      isPasswordEnabled: isPasswordEnabled ? true : false,
      sessionType: meetingType ? meetingType : "OTM",
    };

    const res = await httpRequest(
      {
        method: "POST",
        path: this.CREATE_MEETING_API_ROUTE,
        headers: {
          "Content-Type": "application/json",
        },
      },
      requestBody
    );

    const parseResponse = JSON.parse(res);

    console.log("parseResponse ", parseResponse);

    this.CURRENT_SERVER_URL = parseResponse.serverURL;

    // Set user details
    this.Storage.UPDATE_USER_DETAILS({
      licenseKey: licenseKey,
      name: hostName,
      userId: hostEmail,
      email: hostEmail,
    });

    // Set meeting details
    this.Storage.UPDATE_MEETING_DETAILS({
      meetingName: meetingName,
      meetingType: meetingType,
      meetingCode: JSON.parse(res).meetingCode,
      isPasswordEnabled: isPasswordEnabled,
    });

    return JSON.parse(res).meetingCode || "";
  }

  async CreateNewMeeting({
    licenseKey,
    meetingName,
    meetingPassword,
    isPasswordEnabled,
    hostName,
    hostEmail,
    hostId,
    meetingType,
  }) {
    if (!licenseKey || !hostId || !hostEmail || !hostName) {
      return { status: 0, message: "Host details missing - licenseKey, HostID, HostEmail, HostName" };
    }

    // Reset Store
    this.Storage.RESET_STORE({ user: true, meeting: true });

    const requestBody = {
      apiKey: licenseKey,
      hostId,
      hostName,
      hostEmail,
      meetingName: meetingName || "Meeting 1",
      meetingPassword: meetingPassword || "",
      isPasswordEnabled: !!isPasswordEnabled,
      sessionType: meetingType || "OTM",
    };

    try {
      const res = await httpRequest(
        {
          method: "POST",
          path: this.CREATE_MEETING_API_ROUTE,
          headers: { "Content-Type": "application/json" },
        },
        requestBody
      );

      const parseResponse = JSON.parse(res);
      console.log("parseResponse:", parseResponse);

      if (parseResponse.status === 0) {
        console.log("API Error:", parseResponse.message);
        return { status: 0, message: parseResponse.message };
      }

      this.CURRENT_SERVER_URL = parseResponse.serverUrl;

      // Set user details
      this.Storage.UPDATE_USER_DETAILS({
        licenseKey,
        name: hostName,
        userId: hostEmail,
        email: hostEmail,
      });

      // Set meeting details
      this.Storage.UPDATE_MEETING_DETAILS({
        meetingName,
        meetingType,
        meetingCode: parseResponse.meetingCode,
        isPasswordEnabled,
      });

      return { status: 1, meetingCode: parseResponse.meetingCode };
    } catch (error) {
      console.error("CreateNewMeeting Error:", error);
      return { status: 0, message: error.message || "An error occurred while creating the meeting" };
    }
  }

  /**
   * Join meeting
   */
  async JoinMeeting({ meetingCode, userId, userName, userEmail }) {
    if (!userName || !userEmail) {
      throw new Error("User details missing - name, email");
    }

    const meeting = await this.GetMeetingInfo({ meetingCode, userId });
    if (!meeting.isActive && !meeting.isSessionHost) {
      throw new Error("Meeting Code is not valid! Try valid meeting code.");
    }

    // Set user details
    this.Storage.UPDATE_USER_DETAILS({
      name: userName,
      userId: userId,
      email: userEmail,
      host: meeting.isSessionHost,
    });

    // Set meeting details
    this.Storage.UPDATE_MEETING_DETAILS({
      meetingName: meeting.meetingName,
      meetingType: meeting.meetingType,
      meetingCode: meetingCode,
      isPasswordEnabled: meeting.isPasswordEnabled,
    });

    return meeting;
  }

  /**
   * Validate meeting password
   */
  async ValidateMeeting({ meetingPassword }) {
    if (!meetingPassword) {
      throw new Error("Authentication details missing - Password");
    }

    const store = this.Storage.GET_STORE();

    const requestBody = {
      meetingCode: store.meeting.meetingCode,
      meetingPassword,
      userId: store.user.userId,
    };

    try {
      const res = await httpRequest(
        {
          method: "POST",
          path: this.MEETING_VALIDATE_API_ROUTE,
          headers: {
            "Content-Type": "application/json",
          },
        },
        requestBody
      );

      const parseRes = JSON.parse(res);
      return store.meeting.meetingCode;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Get meeting information
   */
  async GetMeetingInfo({ meetingCode, userId, licenseKey }) {
    if (!meetingCode) throw new Error("Meeting code required");
    if (!userId) throw new Error("User ID required");

    const requestBody = {
      meetingCode: meetingCode,
      userId: userId,
      apiKey: licenseKey,
    };

    const res = await httpRequest(
      {
        method: "POST",
        path: this.MEETING_INFO_API_ROUTE,
        headers: {
          "Content-Type": "application/json",
        },
      },
      requestBody
    );

    const parseRes = JSON.parse(res);

    if (parseRes.isSessionHost) {
      parseRes.isActive = true;
    }

    return parseRes;
  }

  /**
   * Reopen meeting
   */
  async ReopenMeeting({ meetingCode, userId }) {
    if (!meetingCode) throw new Error("Meeting code required");

    const requestBody = {
      meetingCode: meetingCode,
      userId: userId,
    };

    await httpRequest(
      {
        method: "POST",
        path: this.MEETING_REOPEN_API_ROUTE,
        headers: {
          "Content-Type": "application/json",
        },
      },
      requestBody
    );

    return true;
  }

  /**
   * Get meeting storage helper
   */
  getMeetingStore() {
    return this.Storage.GET_STORE();
  }

  /**
   * Connect to meeting
   */
  ConnectMeetingRoom(sessionId) {
    return new Promise(async (resolve, reject) => {
      try {
        // Check if socket is active
        if (this.MemStore.sessionRoom)
          return reject(new Error("Connection Error. Try again."));
        if (!sessionId) return reject(new Error("Invalid session ID"));

        // Get meeting info
        const meetInfo = await this.GetMeetingInfo({
          meetingCode: this.MemStore.sessionId,
          userId: this.Storage.GET_STORE().user.userId,
        });

        // Set broadcast ID
        this.MemStore.sessionId = sessionId;

        // Set session options
        this.MemStore.sessionSettings = {
          broadcastConfiguration: broadcastConfiguration,
          sessionId: this.MemStore.sessionId,
          sessionName: this.Storage.GET_STORE().meeting.meetingName,
          sessionRole: "start",
          userName: this.Storage.GET_STORE().user.name,
          useSimulcast: true,
          useSharingSimulcast: true,
          forceTcp: false,
          produce: true,
          consume: true,
          forceH264: false,
          forceVP9: false,
          svc: undefined,
          datachannel: true,
          isHlsBroadcast: false,
          storageHelper: this.Storage,
          eventEmitter: this.eventEmitter,
          serverUrl: meetInfo.serverUrl,
        };

        setTimeout(async () => {
          // Create new room and join
          try {
            const room = new MediasoupWrapper(this.MemStore.sessionSettings);
            await room.join();

            // Store the room object
            this.MemStore.sessionRoom = room;

            // Start pinging server
            const i = setInterval(() => {
              this.Ping();
            }, 10000);

            this.MemStore.intervals.push(i);

            return resolve({
              status: true,
            });
          } catch (e) {
            return reject(e);
          }
        }, 1000);
      } catch (e) {
        console.error(e);
        return reject(new Error("Server not available at the moment"));
      }
    });
  }

  /**
   * Get Local Time
   */
  GetLocalTime() {
    let date = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }

  /**
   * Format Date to AMPM
   */
  FormatDate(date) {
    date = new Date(date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }

  /**
   * End Call
   */
  async EndMeeting() {
    // Close connection
    if (this.MemStore.sessionRoom) {
      await this.MemStore.sessionRoom.disableWebcam();
      await this.MemStore.sessionRoom.disableMic();
      this.MemStore.sessionRoom.close();
    }

    // Clear all intervals
    this.MemStore.intervals.forEach((i) => clearInterval(i));

    // Stop all media tracks
    _stopAllMediaTracks();

    // Reset data on end
    this.Storage.RESET_STORE({ user: false, meeting: true });
  }

  /**
   * Leave Call
   */
  async LeaveMeeting() {
    // Close connection
    if (this.MemStore.sessionRoom) {
      await this.MemStore.sessionRoom.disableWebcam();
      await this.MemStore.sessionRoom.disableMic();
      this.MemStore.sessionRoom.close();
    }

    // Stop all media tracks
    _stopAllMediaTracks();

    // Reset data on end
    this.Storage.RESET_STORE({ user: true, meeting: true });
  }

  /**
   * Disable web camera
   */
  async DisableWebcam() {
    await this.MemStore.sessionRoom.disableWebcam();
  }

  /**
   * Enable web camera
   */
  async EnableWebcam() {
    await this.MemStore.sessionRoom.enableWebcam();
    await this.RenderLocalVideo({
      selfVideoElementId: this.MemStore.selfVideoElementId,
      largeSelfVideoElementId: this.MemStore.largeSelfVideoElementId,
    });
  }

  /**
   * Disable microphone
   */
  async DisableMicrophone() {
    await this.MemStore.sessionRoom.disableMic();
  }

  /**
   * Enable microphone
   */
  async EnableMicrophone() {
    await this.MemStore.sessionRoom.enableMic();
  }

  /**
   * Enable screen sharing
   */
  async EnableScreenSharing() {
    await this.MemStore.sessionRoom.enableScreenShare();
    await this.RenderLocalVideo({
      selfVideoElementId: this.MemStore.selfVideoElementId,
      largeSelfVideoElementId: this.MemStore.largeSelfVideoElementId,
    });
  }

  /**
   * Disable screen sharing
   */
  async DisableScreenSharing() {
    await this.MemStore.sessionRoom.disableScreenShare();
    await this.RenderLocalVideo({
      selfVideoElementId: this.MemStore.selfVideoElementId,
      largeSelfVideoElementId: this.MemStore.largeSelfVideoElementId,
    });
  }

  /**
   * Send global chat message
   * @param {*} text
   * @param {*} extra
   */
  SendChatMessage(text, extra) {
    if (this.MemStore.sessionRoom)
      this.MemStore.sessionRoom.sendChatMessage(text, extra);
  }

  /**
   * Notify whose typing right now to all
   * @param {*} userName
   */
  NotifyUserIsTyping(userName) {
    try {
      if (!this.lazyChatTypingNotifier) {
        this.MemStore.sessionRoom.sendRemoteCommand("isTyping", userName);
        this.lazyChatTypingNotifier = setTimeout(() => {
          this.lazyChatTypingNotifier = null;
        }, 1000);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  /**
   * Render video stream
   * @param {*} videoElementId
   * @returns
   */
  RenderLocalVideo({ selfVideoElementId, largeSelfVideoElementId }) {
    return new Promise((resolve, reject) => {
      if (
        this.MemStore.sessionSettings &&
        this.MemStore.sessionSettings.produce
      ) {
        try {
          if (!this.MemStore.selfVideoElementId)
            this.MemStore.selfVideoElementId = selfVideoElementId;
          if (!this.MemStore.largeSelfVideoElementId)
            this.MemStore.largeSelfVideoElementId = largeSelfVideoElementId;

          // Create new stream to attach
          let isVideoSet = null;
          let isAudioSet = null;
          let stream = new MediaStream();

          // Fetch own video from the store
          let waitForProducingStreams = setInterval(() => {
            if (this.MemStore.sessionRoom) {
              let videoElement = document.getElementById(
                this.MemStore.selfVideoElementId
              );

              // Attach video track
              if (
                (this.MemStore.sessionRoom._webcamProducer ||
                  this.MemStore.sessionRoom._shareProducer) &&
                !isVideoSet
              ) {
                if (this.MemStore.sessionRoom._webcamProducer) {
                  stream.addTrack(
                    this.MemStore.sessionRoom._webcamProducer.track
                  );
                  isVideoSet = true;
                } else if (this.MemStore.sessionRoom._shareProducer) {
                  stream.addTrack(
                    this.MemStore.sessionRoom._shareProducer.track
                  );
                  isVideoSet = true;
                }
              }

              // Attach audio track
              if (this.MemStore.sessionRoom._micProducer && !isAudioSet) {
                stream.addTrack(this.MemStore.sessionRoom._micProducer.track);
                isAudioSet = true;
              }

              // If audio video available
              if (isVideoSet && isAudioSet && videoElement) {
                if (
                  document.getElementById(this.MemStore.largeSelfVideoElementId)
                )
                  document.getElementById(
                    this.MemStore.largeSelfVideoElementId
                  ).srcObject = stream;
                videoElement.srcObject = stream;
                videoElement.muted = true;
                videoElement.autoplay = true;
                clearInterval(waitForProducingStreams);
                return resolve();
              }
            }
          }, 1000);
        } catch (e) {
          console.error(e);
          return reject(e);
        }
      } else {
        return resolve();
      }
    });
  }

  /**
   * Keep meeting alive
   */
  Ping() {
    try {
      httpRequest(
        {
          method: "POST",
          path: this.PING_API_ROUTE,
          headers: {
            "Content-Type": "application/json",
          },
        },
        {
          meetingCode: this.Storage.GET_STORE().meeting.meetingCode,
          userId: this.Storage.GET_STORE().user.userId,
        }
      );
    } catch (e) {
      console.error(e);
    }
  }

  // === OLD FUNCTIONS

  ShowPeerThumbnail() {
    try {
      // For each consumer in consumers
      Object.values(store.getters.meet.consumers).forEach((consumerObj) => {
        // Get consumer from memory
        const consumer = this.Storage.MEM_STORE.sessionRoom._consumers.get(
          consumerObj.id
        );
        if (consumer && consumer.track) {
          // If current track is video
          if (consumer.track.kind === "video") {
            // Search for existing element, if not found, create new and attach stream
            let videoElement = document.getElementById(
              consumerObj.peerId + "-video-thumbnail"
            );
            if (videoElement) {
              const stream = new MediaStream();
              stream.addTrack(consumer.track);
              videoElement.srcObject = stream;
              videoElement.autoplay = true;
            }
          } else if (consumer.track.kind === "audio") {
            // Search for existing element, if not found, create new and attach stream
            let audioElement = document.getElementById(
              consumerObj.peerId + "-audio-thumbnail"
            );
            if (audioElement) {
              // Create media stream
              const stream = new MediaStream();
              stream.addTrack(consumer.track);
              audioElement.srcObject = stream;
              audioElement.autoplay = true;
            }
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  RenderPeerVideos() {
    try {
      // For each consumer in consumers
      Object.values(store.getters.meet.consumers).forEach((consumerObj) => {
        // Get consumer from memory
        const consumer = this.Storage.MEM_STORE.sessionRoom._consumers.get(
          consumerObj.id
        );
        if (consumer && consumer.track) {
          // If current track is video
          if (consumer.track.kind === "video") {
            // Search for existing element, if not found, create new and attach stream
            let videoElement = document.getElementById(
              consumerObj.peerId + "-video"
            );
            if (videoElement && videoElement.paused) {
              const stream = new MediaStream();
              stream.addTrack(consumer.track);
              videoElement.srcObject = stream;
              videoElement.autoplay = true;
            }
          } else if (consumer.track.kind === "audio") {
            // Search for existing element, if not found, create new and attach stream
            let audioElement = document.getElementById(
              consumerObj.peerId + "-audio"
            );
            if (audioElement) {
              // Create media stream
              const stream = new MediaStream();
              stream.addTrack(consumer.track);
              audioElement.srcObject = stream;
              audioElement.autoplay = true;
            }
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  async RaiseQuestionToHost(peerId) {
    if (this.MemStore.sessionHostId) {
      await this.Storage.MEM_STORE.sessionRoom.sendRemoteCommand(
        "raiseQuestion",
        peerId
      );
    } else {
      throw new Error("Host is not available");
    }
  }

  async DisableAllVideos() {
    await this.Storage.MEM_STORE.sessionRoom.disableAllVideoConsumers();
  }

  async EnableAllVideos() {
    await this.Storage.MEM_STORE.sessionRoom.enableAllVideoConsumers();
  }

  async DisableAllAudios() {
    await this.Storage.MEM_STORE.sessionRoom.disableAllAudioConsumers();
  }

  async EnableAllAudios() {
    await this.Storage.MEM_STORE.sessionRoom.enableAllAudioConsumers();
  }

  async EnablePeerMicrophone(peerId) {
    if (
      store.getters.meet.peers[peerId] &&
      !store.getters.meet.peers[peerId].isMicEnabled
    ) {
      await this.Storage.MEM_STORE.sessionRoom.sendRemoteCommand(
        "enableMic",
        peerId
      );
      // Set raise hand to false
      if (
        store.getters.meet.peers[peerId] &&
        store.getters.meet.peers[peerId].handRaised
      )
        store.dispatch("SET_PEER_HAND_RAISED", {
          peerId: peerId,
          value: false,
        });
    } else {
      throw new Error("User microphone is already enabled");
    }
  }

  async DisablePeerMicrophone(peerId) {
    if (
      store.getters.meet.peers[peerId] &&
      store.getters.meet.peers[peerId].isMicEnabled
    ) {
      await this.Storage.MEM_STORE.sessionRoom.sendRemoteCommand(
        "disableMic",
        peerId
      );
    } else {
      throw new Error("User microphone is already disabled");
    }
  }

  async MuteAllPeers() {
    try {
      if (
        store.getters.meet.session.sessionHostId !== store.getters.meet.self.id
      ) {
        throw new Error(
          "You do not have enough permissions for this operation"
        );
      }

      await this.Storage.MEM_STORE.sessionRoom.muteAllPeers();
    } catch (e) {
      throw e;
    }
  }

  async UnmuteAllPeers() {
    try {
      if (
        store.getters.meet.session.sessionHostId !== store.getters.meet.self.id
      ) {
        throw new Error(
          "You do not have enough permissions for this operation"
        );
      }

      await this.Storage.MEM_STORE.sessionRoom.unmuteAllPeers();
    } catch (e) {
      throw e;
    }
  }

  async GetAvailableMicrophones() {
    try {
      if (
        navigator &&
        navigator.mediaDevices &&
        navigator.mediaDevices.enumerateDevices
      ) {
        let devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter((d) => d.kind === "audioinput");
      } else {
        throw new Error("Unsupported Browser");
      }
    } catch (e) {
      throw e;
    }
  }

  async GetAvailableCameras() {
    try {
      if (
        navigator &&
        navigator.mediaDevices &&
        navigator.mediaDevices.enumerateDevices
      ) {
        let devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter((d) => d.kind === "videoinput");
      } else {
        throw new Error("Unsupported Browser");
      }
    } catch (e) {
      throw e;
    }
  }

  async GetAvailableSpeakers() {
    try {
      if (
        navigator &&
        navigator.mediaDevices &&
        navigator.mediaDevices.enumerateDevices
      ) {
        let devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter((d) => d.kind === "audiooutput");
      } else {
        throw new Error("Unsupported Browser");
      }
    } catch (e) {
      throw e;
    }
  }

  async PauseVideoTransmission() {
    await this.Storage.MEM_STORE.sessionRoom.pauseVideoProducer();
  }

  async ResumeVideoTransmission() {
    await this.Storage.MEM_STORE.sessionRoom.resumeVideoProducer();
  }

  async SetDefaultCamera(deviceId) {
    await this.Storage.sessionRoom.changeDefaultCamera(deviceId);
  }

  async SetDefaultMicrophone(deviceId) {
    await this.Storage.sessionRoom.changeDefaultMicrophone(deviceId);
  }

  async SetDefaultSpeaker(deviceId) {
    await this.Storage.sessionRoom.changeDefaultSpeakers(deviceId);
  }

  async RefreshConnection() {
    await this.Storage.MEM_STORE.sessionRoom.restartIce();
  }
}

class MediasoupWrapper {
  constructor({
    broadcastConfiguration,
    sessionId,
    sessionName,
    sessionRole,
    userName,
    useSimulcast,
    useSharingSimulcast,
    forceTcp,
    produce,
    consume,
    forceH264,
    forceVP9,
    svc,
    datachannel,
    isHlsBroadcast,
    storageHelper,
    eventEmitter,
    serverUrl,
  }) {
    // Event Emitter
    this.eventEmitter = eventEmitter;

    // Set video configuration
    this.VIDEO_CONSTRAINTS = {
      mvga: broadcastConfiguration.video
        ? broadcastConfiguration.video
        : {
          width: { ideal: 480 },
          height: { ideal: 360 },
          frameRate: { ideal: 10, max: 15 },
        },
    };

    // Set audio configuration
    this.AUDIO_CONSTRANTS = broadcastConfiguration.audio
      ? broadcastConfiguration.audio
      : {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: false,
        googEchoCancellation: true,
        googAutoGainControl: false,
        googExperimentalAutoGainControl: false,
        googNoiseSuppression: true,
        googExperimentalNoiseSuppression: true,
        googHighpassFilter: true,
        googTypingNoiseDetection: true,
        googBeamforming: false,
        googArrayGeometry: false,
        googAudioMirroring: false,
        googNoiseReduction: true,
        mozNoiseSuppression: true,
        mozAutoGainControl: false,
      };

    this.PC_PROPRIETARY_CONSTRAINTS = {
      optional: [{ googDscp: true }],
    };

    // Used for simulcast webcam video.
    this.WEBCAM_SIMULCAST_ENCODINGS =
      broadcastConfiguration.videoSimulcastEncodings
        ? broadcastConfiguration.videoSimulcastEncodings
        : [
          // { scaleResolutionDownBy: 4, maxBitrate: 128000, maxFramerate: 15 },
          { scaleResolutionDownBy: 2, maxBitrate: 200000, maxFramerate: 15 },
          // { scaleResolutionDownBy: 1, maxBitrate: 300000, maxFramerate: 15 }
        ];

    // Used for VP9 webcam video.
    this.WEBCAM_KSVC_ENCODINGS = [{ scalabilityMode: "S3T3_KEY" }];

    // Used for simulcast screen sharing.
    this.SCREEN_SHARING_SIMULCAST_ENCODINGS =
      broadcastConfiguration.screenSimulcastEncodings
        ? broadcastConfiguration.screenSimulcastEncodings
        : [
          { scaleResolutionDownBy: 1, dtx: true, maxBitrate: 256000 },
          // { scaleResolutionDownBy: 1, dtx: true, maxBitrate: 512000 }
        ];

    // Used for VP9 screen sharing.
    this.SCREEN_SHARING_SVC_ENCODINGS = [
      { scalabilityMode: "S3T3", dtx: true },
    ];

    // HLS parameter
    this._isHlsBroadcast = isHlsBroadcast;

    // Create new peer ID
    this._peerId = uuidv4();

    // Closed flag.
    // @type {Boolean}
    this._closed = false;

    // Display name.
    // @type {String}
    this._userName = userName;

    // Session ID
    this._sessionId = sessionId;

    // Session Name
    this._sessionName = sessionName;

    // Device info.
    // @type {Object}
    this._device = this._getBrowserInfo();

    // Whether we want to force RTC over TCP.
    // @type {Boolean}
    this._forceTcp = forceTcp;

    // Whether we want to produce audio/video.
    // @type {Boolean}
    this._produce = produce;

    // Whether we should consume.
    // @type {Boolean}
    this._consume = consume;

    // Whether we want DataChannels.
    // @type {Boolean}
    this._useDataChannel = datachannel;

    // Force H264 codec for sending.
    this._forceH264 = Boolean(forceH264);

    // Force VP9 codec for sending.
    this._forceVP9 = Boolean(forceVP9);

    // MediaStream of the external video.
    // @type {MediaStream}
    this._externalVideoStream = null;

    // Next expected dataChannel test number.
    // @type {Number}
    this._nextDataChannelTestNumber = 0;

    // Whether simulcast should be used.
    // @type {Boolean}
    this._useSimulcast = useSimulcast;

    // Whether simulcast should be used in desktop sharing.
    // @type {Boolean}
    this._useSharingSimulcast = useSharingSimulcast;

    // Protoo URL.
    // @type {String}
    this._protooUrl = `wss://${serverUrl}/meeting/?roomId=${this._sessionId}&peerId=${this._peerId}`;

    // protoo-client Peer instance.
    // @type {protooClient.Peer}
    this._protoo = null;

    // mediasoup-client Device instance.
    // @type {mediasoupClient.Device}
    this._mediasoupDevice = null;

    // mediasoup Transport for sending.
    // @type {mediasoupClient.Transport}
    this._sendTransport = null;

    // mediasoup Transport for receiving.
    // @type {mediasoupClient.Transport}
    this._recvTransport = null;

    // Local mic mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._micProducer = null;

    // Local webcam mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._webcamProducer = null;

    // Local share mediasoup Producer.
    // @type {mediasoupClient.Producer}
    this._shareProducer = null;

    // Commanding DataProducer
    // @type {mediasoupClient.DataProducer}
    this._commandDataProducer = null;

    // mediasoup Consumers.
    // @type {Map<String, mediasoupClient.Consumer>}
    this._consumers = new Map();

    // mediasoup DataConsumers.
    // @type {Map<String, mediasoupClient.DataConsumer>}
    this._dataConsumers = new Map();

    // Map of webcam MediaDeviceInfos indexed by deviceId.
    // @type {Map<String, MediaDeviceInfos>}
    this._webcams = new Map();

    // Local Webcam.
    // @type {Object} with:
    // - {MediaDeviceInfo} [device]
    // - {String} [resolution] - 'qvga' / 'vga' / 'hd'.
    this._webcam = {
      device: null,
      resolution: "mvga",
    };

    // Set Storage helper
    this.storageHelper = storageHelper;

    // Media recorder instance
    this._mediaRecorder = null;

    // Set custom SVC scalability mode.
    if (svc) {
      this.WEBCAM_KSVC_ENCODINGS[0].scalabilityMode = `${svc}_KEY`;
      this.SCREEN_SHARING_SVC_ENCODINGS[0].scalabilityMode = svc;
    }

    // Set session host
    if (sessionRole === "start")
      this.storageHelper.UPDATE_USER_DETAILS({
        peerId: this._peerId,
        browser: this._getBrowserInfo(),
      });
  }

  join() {
    return new Promise((resolve, reject) => {
      // Create connection to cluster
      const protooTransport = new protooClient.WebSocketTransport(
        this._protooUrl
      );

      this._protoo = new protooClient.Peer(protooTransport);

      this.eventEmitter.emit("connection-state", "Connecting");
      this.storageHelper.UPDATE_CONNECTION_STATE("Connecting");

      // On open connection
      this._protoo.on("open", async () => {
        await this._startConnections();
        return resolve(true);
      });

      // On connection failure
      this._protoo.on("failed", () => {
        return reject(new Error("WebSocket connection failed"));
      });

      // On connection disconnect
      this._protoo.on("disconnected", () => {
        // Close mediasoup Transports.
        if (this._sendTransport) {
          this._sendTransport.close();
          this._sendTransport = null;
        }

        if (this._recvTransport) {
          this._recvTransport.close();
          this._recvTransport = null;
        }

        this.eventEmitter.emit("connection-state", "Disconnected");
        this.storageHelper.UPDATE_CONNECTION_STATE("Disconnected");
      });

      // On connection close
      this._protoo.on("close", () => {
        if (this._closed) {
          return;
        }
        this.close();
      });

      // eslint-disable-next-line no-unused-vars
      this._protoo.on("request", async (request, accept, reject) => {
        switch (request.method) {
          case "newConsumer": {
            if (!this._consume) {
              console.warn("Refused to consume new consumer");
              break;
            }

            const {
              peerId,
              producerId,
              id,
              kind,
              rtpParameters,
              type,
              appData,
              producerPaused,
            } = request.data;

            try {
              const consumer = await this._recvTransport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                appData: { ...appData, peerId }, // Trick.
              });

              // Set session Host ID
              if (!this.storageHelper.GET_STORE().user.hostId)
                this.storageHelper.UPDATE_USER_DETAILS({
                  hostId: appData.hostId,
                });

              // Store in local map.
              this._consumers.set(consumer.id, consumer);

              consumer.on("transportclose", () => {
                this._consumers.delete(consumer.id);
              });

              const { spatialLayers, temporalLayers } =
                mediasoupClient.parseScalabilityMode(
                  consumer.rtpParameters.encodings[0].scalabilityMode
                );

              // Set Peer can speak
              if (kind === "audio" && peerId !== this._peerId) {
                this.storageHelper.SET_PEER_CAN_SPEAK({
                  peerId: peerId,
                  value: true,
                });
              }

              // Set Peer can speak
              if (kind === "video" && peerId !== this._peerId) {
                this.storageHelper.SET_PEER_CAM_ENABLED({
                  peerId: peerId,
                  value: true,
                });
              }

              // Set session mute all state if session host
              if (
                this.storageHelper.GET_STORE().user.hostId ===
                this.storageHelper.GET_STORE().user.peerId &&
                consumer.track.kind === "audio"
              ) {
                this.storageHelper.SET_SESSION_MUTED(false);
              }

              this.storageHelper.ADD_CONSUMER({
                consumer: {
                  id: consumer.id,
                  type: type,
                  locallyPaused: false,
                  remotelyPaused: producerPaused,
                  rtpParameters: consumer.rtpParameters,
                  spatialLayers: spatialLayers,
                  temporalLayers: temporalLayers,
                  preferredSpatialLayer: spatialLayers - 1,
                  preferredTemporalLayer: temporalLayers - 1,
                  priority: 1,
                  codec:
                    consumer.rtpParameters.codecs[0].mimeType.split("/")[1],
                  track: consumer.track,
                  peerId: peerId,
                  appData: appData,
                },
                peerId: peerId,
              });

              // We are ready. Answer the protoo request so the server will
              // resume this Consumer (which was paused for now if video).
              accept();

              // If audio-only mode is enabled, pause it.
              // if (consumer.kind === 'video' && store.getters.meet.self.audioOnly) { this._pauseConsumer(consumer) }
            } catch (error) {
              console.error("New consumer error: ", error);
            }

            break;
          }

          case "newDataConsumer": {
            if (!this._consume) {
              console.warn("Refused to consume new data consumer");
              break;
            }

            if (!this._useDataChannel) {
              console.warn("Refused to consume new data consumer");
              break;
            }

            const {
              peerId, // NOTE: Null if bot.
              dataProducerId,
              id,
              sctpStreamParameters,
              label,
              protocol,
              appData,
            } = request.data;

            try {
              const dataConsumer = await this._recvTransport.consumeData({
                id,
                dataProducerId,
                sctpStreamParameters,
                label,
                protocol,
                appData: { ...appData, peerId }, // Trick.
              });

              // Store in the map.
              this._dataConsumers.set(dataConsumer.id, dataConsumer);

              dataConsumer.on("transportclose", () => {
                this._dataConsumers.delete(dataConsumer.id);
              });

              dataConsumer.on("close", () => {
                this._dataConsumers.delete(dataConsumer.id);
              });

              dataConsumer.on("error", (error) => {
                console.error("Data consumer error: ", error);
              });

              dataConsumer.on("message", async (message) => {
                switch (dataConsumer.label) {
                  case "command":
                    try {
                      let remoteCommandMessage = JSON.parse(message);

                      if (remoteCommandMessage.command === "chatMessage") {
                        let parseMessage = JSON.parse(
                          remoteCommandMessage.peerId
                        );
                        this.storageHelper.MEM_STORE.chatMessages.push({
                          ID: `${this._userName}-${uuidv4()}`,
                          Time: new Date().toISOString(),
                          Username: parseMessage.userName,
                          Message: parseMessage.message,
                          Extra: parseMessage.extra,
                          isRead: false,
                        });

                        this.eventEmitter.emit(
                          "chat-messages",
                          this.storageHelper.MEM_STORE.chatMessages
                        );
                      }

                      // Enable Microphone
                      if (
                        remoteCommandMessage.command === "enableMic" &&
                        remoteCommandMessage.peerId ===
                        store.getters.meet.self.id
                      ) {
                        await this.enableMic();
                      }

                      // Disable Microphone
                      if (
                        remoteCommandMessage.command === "disableMic" &&
                        remoteCommandMessage.peerId ===
                        store.getters.meet.self.id
                      ) {
                        await this.disableMic();
                      }

                      // Mute All
                      if (
                        remoteCommandMessage.command === "muteAll" &&
                        remoteCommandMessage.peerId ===
                        store.getters.meet.self.id
                      ) {
                        await this.disableMic();
                        this.storageHelper.SET_SESSION_MUTED(true);
                      }

                      // Unmute All
                      if (
                        remoteCommandMessage.command === "unmuteAll" &&
                        remoteCommandMessage.peerId ===
                        store.getters.meet.self.id
                      ) {
                        await this.enableMic();
                        this.storageHelper.SET_SESSION_MUTED(false);
                      }

                      // Set Current typing user
                      if (
                        remoteCommandMessage.command === "isTyping" &&
                        remoteCommandMessage.peerId !==
                        store.getters.meet.self.id
                      ) {
                        this.storageHelper.SET_ACTIVE_USER_TYPING(
                          remoteCommandMessage.peerId
                        );
                        setTimeout(() => {
                          this.storageHelper.SET_ACTIVE_USER_TYPING(null);
                        }, 1000);
                      }

                      // Raise Hand
                      if (
                        remoteCommandMessage.command === "raiseQuestion" &&
                        store.getters.meet.session.sessionHostId ===
                        this._peerId
                      ) {
                        this.SET_PEER_HAND_RAISED({
                          peerId: remoteCommandMessage.peerId,
                          value: true,
                        });
                      }
                    } catch {
                      // None
                    }
                    break;
                }
              });

              // We are ready. Answer the protoo request.
              accept();
            } catch (error) {
              console.error(`Error creating a DataConsumer: ${error}`);
              throw error;
            }

            break;
          }
        }
      });

      this._protoo.on("notification", (notification) => {
        switch (notification.method) {
          case "producerScore": {
            const { producerId, score } = notification.data;
            this.eventEmitter.emit("producer-score", { producerId, score });
            this.storageHelper.SET_PRODUCER_SCORE({
              producerId,
              score,
            });
            break;
          }

          case "newPeer": {
            const peer = notification.data;
            this.storageHelper.ADD_PEER({
              peer: {
                ...peer,
                consumers: [],
                dataConsumers: [],
                raiseHand: false,
                isMicEnabled: false,
                isCamEnabled: false,
              },
            });

            this.eventEmitter.emit(
              "new-peer",
              this.storageHelper.MEM_STORE.peers
            );
            break;
          }

          case "peerClosed": {
            const { peerId } = notification.data;
            this.eventEmitter.emit("terminate-peer", { peer });
            this.storageHelper.REMOVE_PEER({ peerId });
            // removeElement(peerId)
            break;
          }

          case "peerDisplayNameChanged": {
            const { peerId, userName } = notification.data;
            // store.dispatch('SET_PEER_DISPLAY_NAME', { userName, peerId })
            break;
          }

          case "consumerClosed": {
            const { consumerId } = notification.data;
            const consumer = this._consumers.get(consumerId);

            if (!consumer) break;

            consumer.close();
            this._consumers.delete(consumerId);

            const { peerId } = consumer.appData;
            this.eventEmitter.emit("terminate-peer", { peer });
            this.storageHelper.REMOVE_CONSUMER({ consumerId, peerId });

            // if (consumer.track.kind === 'video') removeElement(`${peerId}-video`)
            // else removeElement(`${peerId}-audio`)

            // Set Peer can speak
            if (consumer.track.kind === "audio" && peerId !== this._peerId) {
              this.storageHelper.SET_PEER_CAN_SPEAK({
                peerId: peerId,
                value: false,
              });
            }

            // Set Peer camera status
            if (consumer.track.kind === "video" && peerId !== this._peerId) {
              this.storageHelper.SET_PEER_CAM_ENABLED({
                peerId: peerId,
                value: false,
              });
            }

            break;
          }

          case "consumerPaused": {
            const { consumerId } = notification.data;
            const consumer = this._consumers.get(consumerId);

            if (!consumer) {
              break;
            }
            consumer.pause();

            this.storageHelper.SET_CONSUMER_PAUSED({
              consumerId,
              originator: "remote",
            });

            // Set Peer can speak
            if (
              consumer.track.kind === "audio" &&
              consumer.appData.peerId !== this._peerId
            ) {
              this.storageHelper.SET_PEER_CAN_SPEAK({
                peerId: consumer.appData.peerId,
                value: false,
              });
            }

            // Set Peer camera status
            if (
              consumer.track.kind === "video" &&
              consumer.appData.peerId !== this._peerId
            ) {
              this.storageHelper.SET_PEER_CAM_ENABLED({
                peerId: consumer.appData.peerId,
                value: false,
              });
            }
            break;
          }

          case "consumerResumed": {
            const { consumerId } = notification.data;
            const consumer = this._consumers.get(consumerId);

            if (!consumer) {
              break;
            }
            consumer.resume();

            this.storageHelper.SET_CONSUMER_RESUMED({
              consumerId,
              originator: "remote",
            });

            // Set Peer can speak
            if (
              consumer.track.kind === "audio" &&
              consumer.appData.peerId !== this._peerId
            ) {
              this.storageHelper.SET_PEER_CAN_SPEAK({
                peerId: consumer.appData.peerId,
                value: true,
              });
            }

            // Set Peer camera status
            if (
              consumer.track.kind === "video" &&
              consumer.appData.peerId !== this._peerId
            ) {
              this.storageHelper.SET_PEER_CAM_ENABLED({
                peerId: consumer.appData.peerId,
                value: true,
              });
            }

            break;
          }

          case "consumerLayersChanged": {
            const { consumerId, spatialLayer, temporalLayer } =
              notification.data;
            const consumer = this._consumers.get(consumerId);

            if (!consumer) {
              break;
            }

            this.storageHelper.SET_CONSUMER_CURRENT_LAYERS({
              consumerId,
              spatialLayer,
              temporalLayer,
            });
            break;
          }

          case "consumerScore": {
            const { consumerId, score } = notification.data;
            this.storageHelper.SET_CONSUMER_SCORE({ consumerId, score });
            break;
          }

          case "dataConsumerClosed": {
            const { dataConsumerId } = notification.data;
            const dataConsumer = this._dataConsumers.get(dataConsumerId);

            if (!dataConsumer) {
              break;
            }

            dataConsumer.close();
            this._dataConsumers.delete(dataConsumerId);

            const { peerId } = dataConsumer.appData;
            this.storageHelper.REMOVE_DATA_CONSUMER({ dataConsumerId, peerId });
            break;
          }

          case "activeSpeaker": {
            const { peerId } = notification.data;
            this.storageHelper.SET_ROOM_ACTIVE_SPEAKER({ peerId });
            break;
          }

          default:
        }
      });
    });
  }

  async _startConnections() {
    try {
      this._mediasoupDevice = new mediasoupClient.Device();

      const routerRtpCapabilities = await this._protoo.request(
        "getRouterRtpCapabilities"
      );

      await this._mediasoupDevice.load({ routerRtpCapabilities });

      // NOTE: Stuff to play remote audios due to browsers' new autoplay policy.
      // Just get access to the mic and DO NOT close the mic track for a while.
      // Super hack!
      {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: this.AUDIO_CONSTRANTS,
        });

        mediaStreamTracks.push(stream);
        const audioTrack = stream.getAudioTracks()[0];
        audioTrack.enabled = false;
        setTimeout(() => audioTrack.stop(), 120000);
      }

      // Create producer transport
      if (this._produce) {
        const transportInfo = await this._protoo.request(
          "createWebRtcTransport",
          {
            forceTcp: this._forceTcp,
            producing: true,
            consuming: false,
            sctpCapabilities: this._useDataChannel
              ? this._mediasoupDevice.sctpCapabilities
              : undefined,
          }
        );

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
        } = transportInfo;

        this._sendTransport = this._mediasoupDevice.createSendTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: [],
          proprietaryConstraints: this.PC_PROPRIETARY_CONSTRAINTS,
        });

        this._sendTransport.on(
          "connect",
          ({ dtlsParameters }, callback, errback) => {
            this._protoo
              .request("connectWebRtcTransport", {
                transportId: this._sendTransport.id,
                dtlsParameters,
              })
              .then(callback)
              .catch(errback);
          }
        );

        this._sendTransport.on(
          "produce",
          async ({ kind, rtpParameters, appData }, callback, errback) => {
            try {
              // eslint-disable-next-line no-shadow
              const { id } = await this._protoo.request("produce", {
                transportId: this._sendTransport.id,
                kind,
                rtpParameters,
                appData:
                  this.storageHelper.GET_STORE().user.hostId === this._peerId
                    ? { ...appData, hostId: this._peerId }
                    : appData,
              });

              callback({ id });
            } catch (error) {
              errback(error);
            }
          }
        );

        this._sendTransport.on(
          "producedata",
          async (
            { sctpStreamParameters, label, protocol, appData },
            callback,
            errback
          ) => {
            try {
              // eslint-disable-next-line no-shadow
              const { id } = await this._protoo.request("produceData", {
                transportId: this._sendTransport.id,
                sctpStreamParameters,
                label,
                protocol,
                appData,
              });

              callback({ id });
            } catch (error) {
              errback(error);
            }
          }
        );
      }

      // Create consumer transport
      if (this._consume) {
        const transportInfo = await this._protoo.request(
          "createWebRtcTransport",
          {
            forceTcp: this._forceTcp,
            producing: false,
            consuming: true,
            sctpCapabilities: this._useDataChannel
              ? this._mediasoupDevice.sctpCapabilities
              : undefined,
          }
        );

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
        } = transportInfo;

        this._recvTransport = this._mediasoupDevice.createRecvTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: [],
        });

        this._recvTransport.on(
          "connect",
          ({ dtlsParameters }, callback, errback) => {
            this._protoo
              .request("connectWebRtcTransport", {
                transportId: this._recvTransport.id,
                dtlsParameters,
              })
              .then(callback)
              .catch(errback);
          }
        );
      }

      // Join now into the room
      const { peers } = await this._protoo.request("join", {
        userName: this._userName,
        device: this._device,
        rtpCapabilities: this._consume
          ? this._mediasoupDevice.rtpCapabilities
          : undefined,
        sctpCapabilities:
          this._useDataChannel && this._consume
            ? this._mediasoupDevice.sctpCapabilities
            : undefined,
      });

      // Set connection state
      this.eventEmitter.emit("connection-state", "Connected");
      this.storageHelper.UPDATE_CONNECTION_STATE("Connected");

      // Set Peers
      for (const peer of peers) {
        this.storageHelper.UPDATE_PEERS({
          peer: {
            ...peer,
            consumers: [],
            dataConsumers: [],
            raiseHand: false,
            isMicEnabled: false,
            isCamEnabled: false,
          },
        });
      }

      // If you are a producer, start the camera and microphone
      if (this._produce) {
        // Set our media capabilities.
        this.storageHelper.UPDATE_MEDIA_CAPABILITIES({
          canSendMic: this._mediasoupDevice.canProduce("audio"),
          canSendWebcam: this._mediasoupDevice.canProduce("video"),
        });

        // Start microphone
        if (this.storageHelper.GET_SETTINGS().microphone) {
          this.enableMic();
        }

        // Start webcamera
        if (this.storageHelper.GET_SETTINGS().camera) {
          this.enableWebcam();
        }

        // For default producer,  On connected, start chat channels
        this._sendTransport.on("connectionstatechange", (connectionState) => {
          console.log(
            "send transport connection state change:",
            connectionState
          );
          if (connectionState === "connected") {
            console.log("send transport connected");
            this.enableCommandDataProducer();
          }
        });
      }
    } catch (error) {
      console.error(error);
      this.close();
      throw new Error("Error connecting to the session: ", error);
    }
  }

  close() {
    if (this._closed) {
      return;
    }

    this._closed = true;

    // Close protoo Peer
    this._protoo.close();

    // Close mediasoup Transports.
    if (this._sendTransport) {
      this._sendTransport.close();
    }
    if (this._recvTransport) {
      this._recvTransport.close();
    }

    this._protoo = null;
    this._sendTransport = null;
    this._recvTransport = null;
    // this.storageHelper.RESET_STORE({user: true, meeting: true})
  }

  async enableWebcam() {
    if (this._webcamProducer) {
      return;
    } else if (this._shareProducer) {
      await this.disableScreenShare();
    }

    if (!this._mediasoupDevice.canProduce("video")) {
      throw new Error("Video device error");
    }

    let track;
    let device;

    try {
      await this._updateWebcams();
      device = this._webcam.device;

      const { resolution } = this._webcam;

      if (!device) {
        throw new Error("no webcam devices");
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { ideal: device.deviceId },
          ...this.VIDEO_CONSTRAINTS[resolution],
        },
      });

      mediaStreamTracks.push(stream);
      track = stream.getVideoTracks()[0];

      let encodings;
      let codec;
      const codecOptions = {
        videoGoogleStartBitrate: 1000,
      };

      if (this._forceH264) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === "video/h264"
        );

        if (!codec) {
          throw new Error("desired H264 codec+configuration is not supported");
        }
      } else if (this._forceVP9) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === "video/vp9"
        );

        if (!codec) {
          throw new Error("desired VP9 codec+configuration is not supported");
        }
      }

      if (this._useSimulcast) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec =
          this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.kind === "video"
          );

        if (
          (this._forceVP9 && codec) ||
          firstVideoCodec.mimeType.toLowerCase() === "video/vp9"
        ) {
          encodings = this.WEBCAM_KSVC_ENCODINGS;
        } else {
          encodings = this.WEBCAM_SIMULCAST_ENCODINGS;
        }
      }

      this._webcamProducer = await this._sendTransport.produce({
        track,
        encodings,
        codecOptions,
        codec,
      });

      this.storageHelper.ADD_PRODUCER({
        producer: {
          id: this._webcamProducer.id,
          mediaType: "video",
          deviceLabel: device.label,
          type: this._getWebcamType(device),
          paused: this._webcamProducer.paused,
          track: this._webcamProducer.track,
          rtpParameters: this._webcamProducer.rtpParameters,
          codec:
            this._webcamProducer.rtpParameters.codecs[0].mimeType.split("/")[1],
        },
      });

      this.storageHelper.SAVE_SETTINGS({ camera: true });
      this.eventEmitter.emit("webcam-status", true);

      this.storageHelper.SET_WEBCAM_INFO({
        defaultCameraId: device.deviceId,
        webcamInProgress: true,
      });

      this._webcamProducer.on("transportclose", () => {
        this._webcamProducer = null;
      });

      this._webcamProducer.on("trackended", () => {
        this.disableWebcam().catch(() => { });
      });
    } catch (error) {
      console.error(`Error enabling webcam: ${error}`);
      if (track) {
        track.stop();
      }
    }
  }

  async pauseVideoProducer() {
    try {
      if (this._webcamProducer && !this._webcamProducer.paused) {
        await this._protoo.request("pauseProducer", {
          producerId: this._webcamProducer.id,
        });
        this._webcamProducer.pause();
        this.storageHelper.SET_PRODUCER_PAUSED({
          producerId: this._webcamProducer.id,
        });
      }

      if (this._shareProducer && !this._shareProducer.paused) {
        await this._protoo.request("pauseProducer", {
          producerId: this._shareProducer.id,
        });
        this._shareProducer.pause();
        this.storageHelper.SET_PRODUCER_PAUSED({
          producerId: this._shareProducer.id,
        });
      }

      this.storageHelper.SET_VIDEO_TRANSMISSION_PAUSE_STATE(true);
    } catch (e) {
      throw e;
    }
  }

  async resumeVideoProducer() {
    try {
      if (this._webcamProducer && this._webcamProducer.paused) {
        await this._protoo.request("resumeProducer", {
          producerId: this._webcamProducer.id,
        });
        this._webcamProducer.resume();
        this.storageHelper.SET_PRODUCER_RESUMED({
          producerId: this._webcamProducer.id,
        });
      }

      if (this._shareProducer && this._shareProducer.paused) {
        await this._protoo.request("resumeProducer", {
          producerId: this._shareProducer.id,
        });
        this._shareProducer.resume();
        this.storageHelper.SET_PRODUCER_RESUMED({
          producerId: this._shareProducer.id,
        });
      }

      this.storageHelper.SET_VIDEO_TRANSMISSION_PAUSE_STATE(false);
    } catch (e) {
      throw e;
    }
  }

  async disableWebcam() {
    if (!this._webcamProducer) {
      return;
    }
    try {
      this._webcamProducer.close();

      this.storageHelper.REMOVE_PRODUCER({
        producerId: this._webcamProducer.id,
      });
      this.eventEmitter.emit("webcam-status", false);

      await this._protoo.request("closeProducer", {
        producerId: this._webcamProducer.id,
      });

      this._webcamProducer = null;
    } catch (error) {
      console.error(`Error closing server-side webcam Producer: ${error}`);
    }

    this.storageHelper.SAVE_SETTINGS({ camera: false });
  }

  async enableMic() {
    if (this._micProducer && this._micProducer.paused) {
      await this.unmuteMicrophone();
      return;
    }

    if (!this._mediasoupDevice.canProduce("audio")) {
      throw new Error("Audio device error");
    }

    let track;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: this.AUDIO_CONSTRANTS,
      });
      mediaStreamTracks.push(stream);
      track = stream.getAudioTracks()[0];
      this._micProducer = await this._sendTransport.produce({
        track,
        codecOptions: {
          opusStereo: 1,
          opusDtx: 1,
        },
      });

      this.storageHelper.ADD_PRODUCER({
        producer: {
          id: this._micProducer.id,
          mediaType: "audio",
          paused: this._micProducer.paused,
          track: this._micProducer.track,
          rtpParameters: this._micProducer.rtpParameters,
          codec:
            this._micProducer.rtpParameters.codecs[0].mimeType.split("/")[1],
        },
      });

      this._micProducer.on("transportclose", () => {
        this._micProducer = null;
      });

      this._micProducer.on("trackended", () => {
        console.warn("Microphone disconnected!");
        this.disableMic().catch(() => { });
      });

      this.storageHelper.SAVE_SETTINGS({ microphone: true });
      this.eventEmitter.emit("microphone-status", true);
    } catch (error) {
      console.error(`Error enabling microphone: ${error}`);
      if (track) {
        track.stop();
      }
    }
  }

  async disableMic() {
    if (!this._micProducer) {
      return;
    }
    try {
      this._micProducer.close();
      this.storageHelper.REMOVE_PRODUCER({ producerId: this._micProducer.id });
      await this._protoo.request("closeProducer", {
        producerId: this._micProducer.id,
      });
      this._micProducer = null;
    } catch (error) {
      console.error(`Error closing server-side mic Producer: ${error}`);
    }

    this.storageHelper.SAVE_SETTINGS({ microphone: false });
    this.eventEmitter.emit("microphone-status", false);
  }

  async enableCommandDataProducer() {
    if (!this._useDataChannel) {
      return;
    }

    try {
      // Create chat DataProducer.
      this._commandDataProducer = await this._sendTransport.produceData({
        ordered: true,
        label: "command",
        priority: "high",
        appData: { info: "remote-procedure-commands" },
      });

      this.storageHelper.ADD_DATA_PRODUCER({
        dataProducer: {
          id: this._commandDataProducer.id,
          sctpStreamParameters: this._commandDataProducer.sctpStreamParameters,
          label: this._commandDataProducer.label,
          protocol: this._commandDataProducer.protocol,
        },
      });

      this._commandDataProducer.on("transportclose", () => {
        this._commandDataProducer = null;
      });

      this._commandDataProducer.on("close", () => {
        this._commandDataProducer = null;
      });

      this._commandDataProducer.on("error", (error) => {
        console.error(`Remote command data producer error: ${error}`);
      });
    } catch (error) {
      console.error(`Remote command data producer error: ${error}`);
      throw error;
    }
  }

  async sendChatMessage(text, extra) {
    if (!this._commandDataProducer) {
      console.error("No Data Producer Found");
      return;
    }

    let msgObj = {
      message: text,
      userName: this._userName,
      extra: {},
    };

    // Prepare extra data
    if (extra) {
      try {
        msgObj.extra = extra;
      } catch {
        // Ignore error
      }
    }

    try {
      this.sendRemoteCommand("chatMessage", JSON.stringify(msgObj));
      this.storageHelper.MEM_STORE.chatMessages.push({
        ID: `${this._userName}-${uuidv4()}`,
        Time: new Date().toISOString(),
        Username: "You",
        Message: text,
        Extra: msgObj.extra,
        isRead: false,
      });

      this.eventEmitter.emit(
        "chat-message",
        this.storageHelper.MEM_STORE.chatMessages
      );
    } catch (error) {
      console.error(`chat DataProducer.send() failed: ${error}`);
    }
  }

  async sendRemoteCommand(command, peerId) {
    try {
      this._commandDataProducer.send(JSON.stringify({ command, peerId }));
    } catch (error) {
      console.error(`sendRemoteCommand failed: ${error}`);
    }
  }

  async enableScreenShare() {
    if (this._shareProducer) return;
    else if (this._webcamProducer) {
      await this.disableWebcam();
    }

    if (!this._mediasoupDevice.canProduce("video")) {
      throw new Error("Cannot share screen at the moment");
    }

    let track;
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        audio: false,
        video: {
          displaySurface: "monitor",
          logicalSurface: true,
          cursor: true,
          width: { max: 1280 },
          height: { max: 720 },
          frameRate: { max: 20 },
        },
      });

      mediaStreamTracks.push(stream);
      track = stream.getVideoTracks()[0];

      let encodings;
      let codec;
      const codecOptions = {
        videoGoogleStartBitrate: 1000,
      };

      if (this._forceH264) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === "video/h264"
        );

        if (!codec) {
          throw new Error("desired H264 codec+configuration is not supported");
        }
      } else if (this._forceVP9) {
        codec = this._mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === "video/vp9"
        );

        if (!codec) {
          throw new Error("desired VP9 codec+configuration is not supported");
        }
      }

      if (this._useSharingSimulcast) {
        // If VP9 is the only available video codec then use SVC.
        const firstVideoCodec =
          this._mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.kind === "video"
          );

        if (
          (this._forceVP9 && codec) ||
          firstVideoCodec.mimeType.toLowerCase() === "video/vp9"
        ) {
          encodings = this.SCREEN_SHARING_SVC_ENCODINGS;
        } else {
          encodings = this.SCREEN_SHARING_SIMULCAST_ENCODINGS.map(
            (encoding) => ({ ...encoding, dtx: true })
          );
        }
      }

      this._shareProducer = await this._sendTransport.produce({
        track,
        encodings,
        codecOptions,
        codec,
        appData: {
          share: true,
        },
      });

      // Set Producer for screen
      this.storageHelper.ADD_PRODUCER({
        producer: {
          id: this._shareProducer.id,
          mediaType: "video",
          type: "share",
          paused: this._shareProducer.paused,
          track: this._shareProducer.track,
          rtpParameters: this._shareProducer.rtpParameters,
          codec:
            this._shareProducer.rtpParameters.codecs[0].mimeType.split("/")[1],
        },
      });

      this._shareProducer.on("transportclose", () => {
        this._shareProducer = null;
      });

      this._shareProducer.on("trackended", () => {
        this.disableScreenShare().catch(() => { });
      });

      this.eventEmitter.emit("screen-share", true);
    } catch (error) {
      console.error("Screen sharing failed: ", error);

      if (error.name !== "NotAllowedError") {
        throw new Error("Permissions denied for screen sharing");
      }

      if (track) {
        track.stop();
      }
      throw new Error("Cannot share screen at the moment");
    }
  }

  async disableScreenShare() {
    if (!this._shareProducer) {
      return;
    }

    try {
      this._shareProducer.close();

      this.storageHelper.REMOVE_PRODUCER({
        producerId: this._shareProducer.id,
      });

      await this._protoo.request("closeProducer", {
        producerId: this._shareProducer.id,
      });

      this._shareProducer = null;

      await this.enableWebcam();

      this.eventEmitter.emit("screen-share", false);
    } catch (error) {
      console.error(`Error closing server-side share Producer: ${error}`);
    }
  }

  async muteMicrophone() {
    try {
      this._micProducer.pause();
      await this._protoo.request("pauseProducer", {
        producerId: this._micProducer.id,
      });
      this.storageHelper.SET_PRODUCER_PAUSED({
        producerId: this._micProducer.id,
      });
      this.storageHelper.SET_MICROPHONE_IN_PROGRESS(false);
      this.storageHelper.SAVE_SETTINGS({ microphone: false });
    } catch (error) {
      console.error(error);
      throw new Error(`Error disabling microphone`);
    }
  }

  async unmuteMicrophone() {
    try {
      if (!this._micProducer) await this.enableMic();
      else {
        this._micProducer.resume();
        await this._protoo.request("resumeProducer", {
          producerId: this._micProducer.id,
        });
        this.storageHelper.SET_PRODUCER_PAUSED({
          producerId: this._micProducer.id,
        });
        this.storageHelper.SET_MICROPHONE_IN_PROGRESS(true);
        this.storageHelper.SAVE_SETTINGS({ microphone: true });
      }
    } catch (error) {
      console.error(error);
      throw new Error(`Error enabling microphone`);
    }
  }

  async changeDefaultCamera(deviceId) {
    if (store.getters.meet.self.defaultCameraId === deviceId) return;

    if (!this._webcamProducer) throw new Error("Webcam is disabled");

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const device = devices.find((device) => device.deviceId === deviceId);
      if (!device) throw new Error("Invalid Device ID");

      this._webcam.device = device;

      // Closing the current video track before asking for a new one (mobiles do not like
      // having both front/back cameras open at the same time).
      this._webcamProducer.track.stop();

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: this._webcam.device.deviceId },
          ...this.VIDEO_CONSTRAINTS[this._webcam.resolution],
        },
      });

      mediaStreamTracks.push(stream);
      const track = stream.getVideoTracks()[0];

      await this._webcamProducer.replaceTrack({ track });

      this.storageHelper.SET_PRODUCER_TRACK({
        producerId: this._webcamProducer.id,
        track: track,
      });

      this.storageHelper.SET_DEFAULT_CAMERA_ID({
        deviceId: this._webcam.device.deviceId,
      });
    } catch (error) {
      throw error;
    }
  }

  async disableAllVideoConsumers() {
    const videoConsumers = Array.from(this._consumers.values()).filter(
      (e) => e.track.kind === "video"
    );
    videoConsumers.forEach((consumer) => consumer.pause());
    this.storageHelper.SET_CONSUMER_SETTINGS({
      receiveVideo: false,
    });
  }

  async enableAllVideoConsumers() {
    const videoConsumers = Array.from(this._consumers.values()).filter(
      (e) => e.track.kind === "video"
    );
    videoConsumers.forEach((consumer) => consumer.resume());
    this.storageHelper.SET_CONSUMER_SETTINGS({
      receiveVideo: true,
    });
  }

  async disableAllAudioConsumers() {
    const audioConsumers = Array.from(this._consumers.values()).filter(
      (e) => e.track.kind === "audio"
    );
    audioConsumers.forEach((consumer) => consumer.pause());
    this.storageHelper.SET_CONSUMER_SETTINGS({
      receiveAudio: false,
    });
  }

  async enableAllAudioConsumers() {
    const audioConsumers = Array.from(this._consumers.values()).filter(
      (e) => e.track.kind === "audio"
    );
    audioConsumers.forEach((consumer) => consumer.resume());
    this.storageHelper.SET_CONSUMER_SETTINGS({
      receiveAudio: true,
    });
  }

  async changeDefaultMicrophone(deviceId) {
    if (store.getters.meet.self.defaultMicrophoneId === deviceId) return;

    if (!this._micProducer) throw new Error("Microphone is disabled");

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const device = devices.find((device) => device.deviceId === deviceId);
      if (!device) throw new Error("Invalid Device ID");

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: { exact: device.deviceId },
          ...this.AUDIO_CONSTRANTS,
        },
      });

      mediaStreamTracks.push(stream);
      const track = stream.getAudioTracks()[0];

      await this._micProducer.replaceTrack({ track });

      this.storageHelper.SET_PRODUCER_TRACK({
        producerId: this._micProducer.id,
        track: track,
      });

      this.storageHelper.SET_DEFAULT_MICROPHONE_ID({
        deviceId: device.deviceId,
      });
    } catch (error) {
      throw error;
    }
  }

  async changeDefaultSpeakers(deviceId) {
    try {
      if (
        !this._consume ||
        Object.values(store.getters.meet.consumers).length === 0
      )
        return;

      // For each consumer in consumers
      Object.values(store.getters.meet.consumers).forEach((consumerObj) => {
        // Get consumer from memory
        const consumer = this._consumers.get(consumerObj.id);
        if (consumer && consumer.track) {
          let audioElement = document.getElementById(
            consumerObj.peerId + "-audio"
          );
          if (audioElement) {
            audioElement.setSinkId(deviceId);
          }
        }
      });

      this.storageHelper.SET_DEFAULT_SPEAKER_ID({ deviceId: deviceId });
    } catch (e) {
      console.error(e);
    }
  }

  async restartIce() {
    try {
      if (this._sendTransport) {
        const iceParameters = await this._protoo.request("restartIce", {
          transportId: this._sendTransport.id,
        });
        await this._sendTransport.restartIce({ iceParameters });
      }

      if (this._recvTransport) {
        const iceParameters = await this._protoo.request("restartIce", {
          transportId: this._recvTransport.id,
        });
        await this._recvTransport.restartIce({ iceParameters });
      }
    } catch (error) {
      console.error(error);
      throw new Error("Failed to refresh connection");
    }
  }

  async muteAllPeers() {
    try {
      Object.keys(store.getters.meet.peers).forEach(async (peerId) => {
        await this.sendRemoteCommand("muteAll", peerId);
      });

      this.storageHelper.SET_SESSION_MUTED(true);
    } catch (error) {
      console.error(error);
      throw new Error("Error with Mute All");
    }
  }

  async unmuteAllPeers() {
    try {
      Object.keys(store.getters.meet.peers).forEach(async (peerId) => {
        await this.sendRemoteCommand("unmuteAll", peerId);
      });
      this.storageHelper.SET_SESSION_MUTED(false);
    } catch (error) {
      console.error(error);
      throw new Error("Error with Unmute All");
    }
  }

  // Local functions start with _
  async _pauseConsumer(consumer) {
    if (consumer.paused) {
      return;
    }

    try {
      await this._protoo.request("pauseConsumer", { consumerId: consumer.id });
      consumer.pause();

      this.storageHelper.SET_CONSUMER_PAUSED({
        consumerId: consumer.id,
        originator: "local",
      });
    } catch (error) {
      console.error("Failed to pause consumer: ", error);
    }
  }

  async _resumeConsumer(consumer) {
    if (!consumer.paused) {
      return;
    }

    try {
      await this._protoo.request("resumeConsumer", { consumerId: consumer.id });
      consumer.resume();

      this.storageHelper.SET_CONSUMER_RESUMED({
        consumerId: consumer.id,
        originator: "local",
      });
    } catch (error) {
      console.error("Failed to resume consumer: ", error);
    }
  }

  async _updateWebcams() {
    // Reset the list.
    this._webcams = new Map();

    const devices = await navigator.mediaDevices.enumerateDevices();

    for (const device of devices) {
      if (device.kind !== "videoinput") continue;
      this._webcams.set(device.deviceId, device);
    }

    const array = Array.from(this._webcams.values());
    const currentWebcamId = this._webcam.device
      ? this._webcam.device.deviceId
      : undefined;

    if (array.length === 0) {
      this._webcam.device = null;
    } else if (!this._webcams.has(currentWebcamId)) {
      this._webcam.device = array[0];
    }

    this.storageHelper.SET_WEBCAM_INFO({
      canChangeWebcam: this._webcams.size > 1,
    });
  }

  _getWebcamType(device) {
    if (/(back|rear)/i.test(device.label)) {
      return "back";
    } else {
      return "front";
    }
  }

  _getBrowserInfo() {
    {
      const ua = navigator.userAgent;
      const browser = bowser.getParser(ua);
      let flag;

      if (browser.satisfies({ chrome: ">=0", chromium: ">=0" })) {
        flag = "chrome";
      } else if (browser.satisfies({ firefox: ">=0" })) {
        flag = "firefox";
      } else if (browser.satisfies({ safari: ">=0" })) {
        flag = "safari";
      } else if (browser.satisfies({ opera: ">=0" })) {
        flag = "opera";
      } else if (browser.satisfies({ "microsoft edge": ">=0" })) {
        flag = "edge";
      } else {
        flag = "unknown";
      }

      return {
        flag,
        name: browser.getBrowserName(),
        version: browser.getBrowserVersion(),
      };
    }
  }
}

function _stopAllMediaTracks() {
  // Close all streams
  mediaStreamTracks.forEach((track) => {
    try {
      track.getTracks().forEach((track) => track.stop());
    } catch (e) {
      console.error(e);
    }
  });

  mediaStreamTracks = [];
}

// Start navigator media source
function StartNavigatorMediaSource(configuration) {
  return new Promise((resolve, reject) => {
    if (!navigator.mediaDevices) {
      return reject(new Error("Your browser does not support the broadcast"));
    }

    navigator.mediaDevices
      .getUserMedia({
        video: configuration.video,
        audio: configuration.audio,
      })
      .then((stream) => {
        return resolve(stream);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}

// Request handler
function httpRequest(options, body) {
  return new Promise((resolve, reject) => {
    try {
      options = {
        ...options,
        host: GoigiConferenceService.prototype.SERVER_BASE_URL,
      };

      fetch(`${options.host}${options.path}`, {
        method: options.method,
        headers: options.headers,
        body: JSON.stringify({
          ...body,
          // apiKey: GoigiConferenceService.prototype.API_KEY,
        }),
      })
        .then((response) => {
          return resolve(response.text());
        })
        .catch((err) => {
          return reject(err);
        });
    } catch (e) {
      return reject(e);
    }
  });
}


export { GoigiConferenceService };
