<template>
  <b-container fluid>
    <b-form @submit.prevent="$emit('submit', newMeetingObj)">
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="4"
        label="License Key"
      >
        <b-form-input
          type="text"
          placeholder="Enter licenseKey"
          v-model="newMeetingObj.licenseKey"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="4"
        label="Meeting Name"
      >
        <b-form-input
          type="text"
          placeholder="Enter meeting name"
          v-model="newMeetingObj.meetingName"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="4"
        label="User Name"
      >
        <b-form-input
          type="text"
          placeholder="Enter user name"
          v-model="newMeetingObj.hostName"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="4"
        label="User Email"
      >
        <b-form-input
          type="email"
          placeholder="Enter user email"
          v-model="newMeetingObj.hostEmail"
          required
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group
        v-if="newMeetingObj.isPasswordEnabled"
        label-cols-sm="4"
        label-cols-lg="4"
        label="Meeting Password"
      >
        <b-form-input
          type="password"
          v-model="newMeetingObj.meetingPassword"
          required
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="8"
        label-cols-lg="8"
        label="Enable Meeting Password"
      >
        <b-form-checkbox
          v-model="newMeetingObj.isPasswordEnabled"
          switch
          disabled
        ></b-form-checkbox>
      </b-form-group> -->
      <hr>
      <b-button
        type='submit'
        block
        variant="primary"
      >
        Start Meeting
      </b-button>
    </b-form>
  </b-container>
</template>
<script>
export default {
  data() {
    
    return {
      newMeetingObj: {
        licenseKey: "",
        hostName: "",
        hostEmail: "",
        meetingName: "",
        isPasswordEnabled: false,
        meetingPassword: ""
      },

      fields: [
        {
          id: "licenseKey",
          label: "license Key",
          type: "text",
          model: "newMeetingObj.licenseKey",
          required: true
        },
        {
          id: "username",
          label: "User Name",
          type: "text",
          model: "newMeetingObj.hostName",
          required: true
        },
        {
          id: "useremail",
          label: "User Email",
          type: "email",
          model: "newMeetingObj.hostEmail",
          required: true
        },
        {
          id: "meetingName",
          label: "Meeting Name",
          type: "text",
          model: "newMeetingObj.meetingName",
          required: true
        }
      ]
    };
  }
};
</script>