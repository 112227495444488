import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Goigi Conference Demo",
    },
  },
  {
    path: "/preview/:meetingCode",
    props: true,
    name: "Preview",
    component: () =>
      import(/* webpackChunkName: "preview" */ "../views/Preview.vue"),
    meta: {
      title: "Goigi Conference Demo",
    },
  },
  {
    path: "/meeting/:meetingCode",
    props: true,
    name: "Meeting",
    component: () =>
      import(/* webpackChunkName: "meeting" */ "../views/MeetingV1.vue"),
    meta: {
      title: "Goigi Conference Demo",
    },
  },
  {
    path: "/meet/:meetingCode",
    props: true,
    name: "Meet",
    component: () =>
      import(/* webpackChunkName: "meet" */ "../views/MeetingV2.vue"),
    meta: {
      title: "Goigi Conference Demo",
    },
  },
  {
    path: "/bot/record/:meetingCode",
    props: true,
    name: "Record",
    component: () => import(/* webpackChunkName: "meet" */ "../views/Bot.vue"),
    meta: {
      title: "Goigi Conference Demo",
    },
  },
  {
    path: "/end/:meetingCode",
    props: true,
    name: "End",
    component: () => import(/* webpackChunkName: "end" */ "../views/End.vue"),
    meta: {
      title: "Goigi Conference Demo",
    },
  },
  {
    path: "/join/:meetingCode",
    props: true,
    name: "Join",
    component: () => import(/* webpackChunkName: "join" */ "../views/Join.vue"),
    meta: {
      title: "Goigi Conference Metting validate",
    },
  },
  {
    name: "error",
    path: "/:catchAll(.*)",
    component: () => import(/* webpackChunkName: "end" */ "../views/404.vue"),
    meta: {
      title: "Page Not Found",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
